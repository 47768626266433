import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import parse from "html-react-parser"
import {Helmet} from "react-helmet";
import "../css/@wordpress/block-library/build-style/style.css"
import "../css/@wordpress/block-library/build-style/theme.css"
//https://www.gatsbyjs.com/plugins/gatsby-source-custom-api-with-fetchoptions/?=json para menu??
import Layout from "../components/layout"
import Seo from "../components/seo"

const BlogPostTemplate = ({ data: { favicon, icono, post } }) => {
  const featuredImage = {
    data: post.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
    alt: post.featuredImage?.node?.alt || ``,
  }
  return (
    <Layout>
    { icono ? (
      <Helmet>
          <link rel="icon" href={favicon.childImageSharp.fixed.src} sizes="32x32" />
          <link rel="icon" href={icono.childImageSharp.fixed.src} sizes="192x192" />
          <link rel="apple-touch-icon" href={icono.childImageSharp.fixed.src} />
          <meta name="msapplication-TileImage" content={icono.childImageSharp.fixed.src} />
          <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/js/bootstrap.min.js" integrity="sha384-cuYeSxntonz0PPNlHhBs68uyIAVpIIOZZ5JqeqvYYIcEL727kskC66kF92t6Xl2V" crossorigin="anonymous"></script>
      </Helmet>
      ) : (
        <Helmet>
            <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/js/bootstrap.min.js" integrity="sha384-cuYeSxntonz0PPNlHhBs68uyIAVpIIOZZ5JqeqvYYIcEL727kskC66kF92t6Xl2V" crossorigin="anonymous"></script>
        </Helmet>
    )}
    <Seo title={post.seo.title} description={post.seo.metaDesc} url={post.seo.canonical} />
      <article
        className="full-wrapper blog-post"
        itemScope
        itemType="http://schema.org/Article"
      >
      <h1 className="h1" itemProp="headline">{parse(post.title)}</h1>

        {!!post.content && (
          <section itemProp="articleBody">{parse(post.content)}</section>
        )}
        <div class="herotitle mt-4 mb-4 pt-5 pb-5 bg-info">
            <div class="container">
                <div class="pt-4 pb-4">
                    <div class="text-center">
                    <div class="subtitulo">¿Necesitas ayuda con la Publicidad Digital?</div>
                        <div class="titulo">Contacta con nuestra Agencia de Publicidad y Marketing</div>
                        <div class="text-center">
                            <p>En Programa TIC, estamos emocionados de ayudarte a navegar por el mundo en constante cambio de la publicidad y el marketing en línea. Nuestra misión es hacer que tu marca destaque y alcance nuevos horizontes en línea. ¡Únete a nosotros
                                en este emocionante viaje y déjanos ser tu socio confiable en la era digital!</p>
                            <p className="text-center"><Link className="btn btn-primary" to="/contacto/">¿Hablamos?</Link></p>
                        </div>
                        <div class="text-center">
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </article>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostById(
    $id: String!
  ) {
    favicon: file(relativePath: { eq: "ico32.jpg" }) {
      childImageSharp {
        fixed(width: 32, quality:100) {
          base64
          width
          height
          src
          srcSet
        }
      }
    }
    icono: file(relativePath: { eq: "icon.png" }) {
      childImageSharp {
        fixed(width: 180, quality:100) {
          base64
          width
          height
          src
          srcSet
        }
      }
    }
    post: wpPost(id: { eq: $id }) {
      id
      excerpt
      content
      title
      seo {
        title
        canonical
        metaDesc
      }
      date(formatString: "MMMM DD, YYYY")
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 1200
              )
            }
          }
        }
      }
    }
  }
`
